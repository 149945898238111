import { AppContext, useAppContextProvider } from "./hook/useApp.context";
import { RouterProvider } from "react-router-dom";
import { Router } from "./page/router";
import PromptModal from "./components/systemModal/PromptModal";
import AlertModal from "./components/systemModal/AlertModal";
import { AlertModalDetail } from "./components/ItemDetailModal";
import Page404 from "./page/404";
import { useEffect } from "react";
import { ifDev } from "./utils/dev";
import { useScrollToTopByPathChange } from "./hook/useScrollTopbyPath";

export const App = () => {
 const contextValue = useAppContextProvider({});

 useEffect(() => {
  if (!contextValue.user) return;
  const target = document.getElementById("Splash");
  if (!target) throw Error();
  target?.classList?.add("fade-out");
  target.style.opacity = "1";
  target.addEventListener("animationend", () => {
   // Option 1: Set pointer-events to none after animation
   // element.style.pointerEvents = 'none';

   // Option 2: Set display to none after animation
   target.style.display = "none";
  });
 }, [!!contextValue.user]);

 if (!contextValue.nfcId) return <div>NFC ID IS NOT FOUNDED</div>;
 if (!contextValue.user) return ifDev(<div>No User Found</div>);

 return (
  <AppContext.Provider value={contextValue}>
   <RouterProvider router={Router} />
   {contextValue.promptModalHook.isOpen && (
    <PromptModal modalHook={contextValue.promptModalHook} />
   )}
   {contextValue.confirmModalHook.isOpen && (
    <PromptModal modalHook={contextValue.promptModalHook} />
   )}
   {contextValue.alertModalHook.isOpen && (
    <AlertModal modalHook={contextValue.alertModalHook} />
   )}
   {contextValue.itemDetailModalHook.isOpen && (
    <AlertModalDetail modalHook={contextValue.itemDetailModalHook} />
   )}
  </AppContext.Provider>
 );
};
