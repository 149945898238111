import { Category } from "../app/apollo/type/graphql";

export const DEFAULT_PAGE_INFO = {
 currentPage: 0,
 rowCount: 0,
 totalPage: 0,
};

export enum Paths {
 "/" = "/",
 "/visit" = "/visit",
 "/list" = "/list",
 "/signup" = "/signup",
 "/place" = "/place",
 "/map" = "/map",
 "/mypage" = "/mypage",
 "/album" = "/album",
 "/search" = "/search",
}

export const CategoryColor: Record<Category, string> = {
 Attractions: "teal",
 Cafe: "red",
 Convention: "amber",
};

export const CategoryImgMap: Record<Category, string> = {
 Attractions: "https://jw.stayjanda.cloud/keyband/attractions_Icon.svg",
 Cafe: "https://jw.stayjanda.cloud/keyband/cafe_Icon.svg",
 Convention: "https://jw.stayjanda.cloud/keyband/convention_Icon.svg",
};
export const Airplan = "https://jw.stayjanda.cloud/keyband/airplane_icon.svg";
