import { useRef, useState } from "react";
import { Photo } from "../components/Photo";
import { KakaoMap } from "../components/Map";
import { CategoryColor, Paths } from "../data/const";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../hook/useApp.context";
import { MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { CheckBox } from "../data/Icon";
import {
 FitemDetailFragment,
 useCouponUseMutation,
 useItemFindByIdQuery,
} from "../app/apollo/type/graphql";
import { getFromUrl } from "../utils/getFromUrl";
import { getSppech } from "../utils/speech";
import { L } from "../data/lang.static";
import { getRefetch } from "../utils/getRefetch";
import { USER_CREATE } from "../apollo/gql/user";
import { MapPin } from "../components/MapPin";
import {
 SwiperSlide,
 Swiper,
 SwiperRef,
 SwiperSlideProps,
 useSwiper,
} from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
interface IProp {
 item: FitemDetailFragment;
}

export const PlaceInformation: React.FC<IProp> = ({ item }) => {
 const [onSound, setOnSound] = useState<boolean>(false);
 const { lang, langCode, user } = useAppContext();

 const checked = user?.Visits?.some((v) => v.itemId === item._id);

 const [couponUse] = useCouponUseMutation({
  awaitRefetchQueries: true,
  ...getRefetch(USER_CREATE),
 });
 const navigate = useNavigate();
 const audioRef = useRef<HTMLAudioElement>(null);
 const GoMain = () => {
  navigate(Paths["/"]);
 };

 const handleSound = () => {
  const audio = audioRef.current as HTMLAudioElement;
  if (audio) {
   if (!audio.paused) {
    audio.pause();
   } else {
    audio.play();
   }
  }
  setOnSound(!onSound);
 };

 const handleUseCoupon = (couponId: string) => {
  if (window.confirm(L["Q_Willing_Coupon"]))
   couponUse({
    variables: {
     itemId: item._id,
     couponId,
    },
   }).then(({ data }) => {
    if (data?.CouponUse.ok) {
     alert(L["R_Coupon_Used"]);
    }
   });
 };

 return (
  <div className="h-auto max-w-[768px] w-full mx-auto flex flex-col bg-neutral-100">
   <audio ref={audioRef} id={"Speech"} src={getSppech(item._id, langCode)} />
   <div className="w-full h-[100vw] md:h-[720px] relative">
    <Photo
     height={64}
     className="z-10 absolute left-[5.5vw] md:left-10 top-[11.1vw] md:top-20 w-[8.8vw] h-[8.8vw] md:w-[64px] md:h-[64px]"
     src={"https://jw.stayjanda.cloud/keyband/back_Icon.svg"}
     onClick={GoMain}
    />
    <CheckBox
     unCheck={!checked}
     width={50}
     height={50}
     className="z-10 absolute  right-[5.5vw] md:right-10 top-[11.1vw] md:top-20 w-[8.8vw] h-[8.8vw] md:w-[64px] md:h-[64px]"
    />
    <div
     id="Pagination"
     className="absolute z-10 gap-1 left-0 right-0 justify-center bottom-[12.3vw] md:bottom-[92px] flex "
    ></div>
    <Swiper
     pagination={{
      clickable: true,
      el: "#Pagination",
      bulletElement: "div",
      bulletClass: "w-1 md:w-2 transition-all h-1 md:h-2 bg-white rounded-full",
      bulletActiveClass: "!w-5 md:!w-10",
     }}
     modules={[Pagination]}
     className="overflow-hidden h-full"
     slidesPerView={1}
    >
     {item.image.map((img, index) => (
      <SwiperSlide key={img.uri + "Img"} className="w-full h-full">
       <Photo
        size={1000}
        className="!object-cover w-full h-full"
        src={img?.uri}
        alt={img.name}
       />
      </SwiperSlide>
     ))}
    </Swiper>
   </div>
   <div className="h-auto flex flex-col items-start w-full bg-white rounded-tl-[9.7vw] rounded-tr-[9.7vw] md:rounded-tl-[70px] md:rounded-tr-[70px] -mt-[8.3vw] md:-mt-[60px] z-10 px-[5.5vw] md:px-10 pt-[8.3vw] md:pt-[60px] pb-[25vw] md:pb-[200px]">
    <div className="w-full flex items-center h-[8.8vw]">
     <p className="text-neutral-700 text-[5vw] md:text-4xl font-semibold">
      {lang(item.name)}
     </p>
    </div>
    {lang(item.address)?.trim() && (
     <div
      onClick={() => {
       if (window.confirm(L["Open_GoogleMap"]))
        window.open(
         `https://www.google.com/maps?q=${item.addressDetail?.lat},${item.addressDetail?.lng}`,
         "_blank"
        );
      }}
      className="mt-[5.5vw] md:mt-[40px] pb-[2.7vw] md:pb-[20px] w-full flex items-center border-b border-neutral-200"
     >
      <MapPinIcon className="min-w-[5.5vw] h-[5.5vw] md:h-10 md:min-w-10" />
      <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-normal ml-[2.7vw] md:ml-5">
       {lang(item.address)}
      </p>
     </div>
    )}
    {item.contact && (
     <div
      onClick={() => {
       let contactStr = item.contact;
       if (item.contact?.startsWith("0")) {
        const [zero, ...rest] = item.contact;
        contactStr = rest.join("");
       }
       window.open(`tel:+82${contactStr}`);
      }}
      className="py-[2.7vw] md:py-[20px] w-full flex items-center border-b border-neutral-200"
     >
      <PhoneIcon className="w-[5.5vw] h-[5.5vw] md:h-10 md:w-10" />
      <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-normal ml-[2.7vw] md:ml-5">
       +82 {item.contact}
      </p>
     </div>
    )}
    <div className="flex flex-col mt-[8.3vw] md:mt-[60px]">
     <div className="flex justify-between items-center w-full h-auto">
      <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-bold">
       {L["Information"]}
      </p>
      {onSound ? (
       <Photo
        width={50}
        height={50}
        className="w-[6.9vw] h-[6.9vw] md:h-[50px] md:w-[50px]"
        src={"https://jw.stayjanda.cloud/keyband/speaker_on.svg"}
        alt={"speaker"}
        onClick={handleSound}
       />
      ) : (
       <Photo
        width={50}
        height={50}
        className="w-[6.9vw] h-[6.9vw] md:h-[50px] md:w-[50px]"
        src={"https://jw.stayjanda.cloud/keyband/speaker_off.svg"}
        alt={"speaker"}
        onClick={handleSound}
       />
      )}
     </div>
     <p className="mt-[5.5vw] md:mt-10 text-neutral-700 text-[3.9vw] md:text-[28px] font-normal">
      {lang(item.information)}
     </p>
    </div>
    {item?.addressDetail?.lat && (
     <>
      <div className="mt-[8.3vw] md:mt-[60px] w-full border-t border-netural-200" />
      <div className="mt-[8.3vw] md:mt-[60px] w-full flex flex-col items-start">
       <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-bold">
        {L["Location"]}
       </p>
       <div className="mt-5 md:mt-10 w-full h-[55.5vw] md:h-[400px] flex items-center justify-center relative">
        <KakaoMap
         style={{
          width: "100%",
          height: "100%",
         }}
         defaultZoom={18}
         defaultCenter={{
          lat: item?.addressDetail?.lat || 0,
          lng: item?.addressDetail?.lng || 0,
         }}
        >
         <MapPin
          lat={item?.addressDetail?.lat || 0}
          lng={item?.addressDetail?.lng || 0}
          color={"red"}
         />
        </KakaoMap>
       </div>
       <p className="mt-[2.7vw] md:mt-5 text-[3.9vw] md:text-[28px]">
        {lang(item.address)}
       </p>
      </div>
     </>
    )}
    <div className="mt-[8.3vw] md:mt-[60px] w-full border-b border-neutral-200" />
    <div className="mt-[8.3vw] md:mt-[60px] flex flex-col w-full">
     {/* <div className="border-t border-neutral-200 mt-[8.3vw] md:mt-[60px]" /> */}
     {!!item.coupon?.length && (
      <>
       <div className="flex flex-col items-start mt-[8.3vw] md:mt-[60px]">
        <div className="flex items-center w-full justify-start h-[8.8vw] md:h-[64px]">
         <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-bold">
          {L["Coupon"]}
         </p>
        </div>
        <div className="w-full flex flex-col mt-[5.5vw] md:mt-10 gap-y-[2.7vw] md:gap-y-[20px]">
         {item.coupon?.map((cp) => {
          const isUsed = user?.useCoupon?.find((ucp) => {
           return cp._id === ucp._id;
          });
          return (
           <div
            key={cp.itemId + "Coupon"}
            className="flex items-center w-full p-[2.7vw] md:p-5 bg-white rounded-[2.7vw] md:rounded-[20px] border border-neutral-200 justify-between"
           >
            <div className="h-[16.6vw] w-[16.6vw] md:h-[120px] md:w-[120px] p-[1.5vw] md:p-2.5 flex items-center justify-center relative">
             <Photo
              width={100}
              height={79}
              className="object-contain w-[12.2vw] h-[9.4vw] md:w-[100px] md:h-[79px]"
              file={cp.photo}
              alt={"coupon_photo"}
             />
            </div>
            <div className="flex flex-col gap-y-[1vw] md:gap-y-[7px]">
             <p className="text-neutral-700 text-[4.44vw] md:text-5xl font-bold ">
              {lang(cp.title)}
             </p>
             <p className="text-neutral-700 text-[3.3vw] md:text-2xl font-normal  mt-[1.1vw] md:mt-2">
              {lang(cp.subText)}
             </p>
            </div>

            <button
             onClick={() => {
              handleUseCoupon(cp._id);
             }}
             disabled={!!isUsed}
             className={twMerge(
              classNames(
               "w-[16.6vw] ml-[2vw] h-[8.3vw] md:w-[120px] md:h-[60px] rounded border-[0.3vw] md:border-2 border-amber-500 justify-center items-center",
               {
                "bg-neutral-200 border-none": isUsed,
               }
              )
             )}
            >
             <p
              className={twMerge(
               classNames(
                "text-center text-[3.3vw] md:text-[24px] text-amber-500 font-semibold leading-none",
                {
                 "text-white": isUsed,
                }
               )
              )}
             >
              {L[isUsed ? "Coupon_Used" : "Use"]}
             </p>
            </button>
           </div>
          );
         })}
        </div>
       </div>
      </>
     )}

     {!!item.bouchers?.length && (
      <div className="w-full flex flex-col mt-[8.3vw] md:mt-[60px]">
       <div className="flex items-center w-full justify-start h-[8.8vw] md:h-[64px]">
        <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-bold">
         {L["Brochure"]}
        </p>
       </div>
       <div className="mt-[5.5vw] md:mt-10 w-full flex flex-col gap-y-[2.7vw] md:gap-y-5">
        {item.bouchers?.map((bc) => {
         return (
          <div
           key={bc.file?._id}
           onClick={() => {
            window.open(bc.file?.uri, "_blank");
           }}
           className="flex items-center w-full p-[2.7vw] md:p-5 bg-white rounded-[2.7vw] md:rounded-[20px] border border-neutral-200 gap-x-[5.5vw] md:gap-x-10"
          >
           <Photo
            width={100}
            height={79}
            className="object-contain w-[16.6vw] h-[16.6vw] md:w-[120px] md:h-[120px] border-0"
            src={"https://jw.stayjanda.cloud/keyband/pdf_icon.svg"}
            alt={"coupon_photo"}
           />
           <div className="flex flex-col gap-y-[1vw] md:gap-y-[7px] w-full">
            <p className="text-neutral-700 text-[5.5vw] md:text-[40px] font-bold leading-none">
             {lang(bc.title)}
            </p>
            <p className="text-neutral-700 text-[2.7vw] md:text-xl font-normal leading-none">
             {lang(bc.subText)}
            </p>
           </div>
           <button className="min-w-[16.6vw] h-[8.3vw] md:min-w-[120px] md:w-[120px] md:h-[60px] rounded border-[0.3vw] md:border-2 border-amber-500 justify-center items-center">
            <p className="text-center text-[3.3vw] md:text-[24px] text-amber-500 font-semibold leading-none">
             {L["View"]}
            </p>
           </button>
          </div>
         );
        })}
       </div>
      </div>
     )}
    </div>
   </div>
  </div>
 );
};

export const PlaceInformationApiWrap = () => {
 const itemId = getFromUrl("itemId")!;
 const { data: itemFindResult } = useItemFindByIdQuery({
  variables: {
   itemId,
   selectAll: false,
  },
  skip: !itemId,
 });
 if (!itemId) return null;

 const item = itemFindResult?.ItemFindById;
 if (!item) return null;
 return <PlaceInformation item={item} />;
};

export default PlaceInformation;
