import { useModal } from "./useModal";
import React, { useContext, useEffect, useId, useState } from "react";
import {
 useAdminQuery,
 useUserCreateOrFindQuery,
} from "../app/apollo/type/graphql";
import { IPromptModalInfo } from "../components/systemModal/PromptModal";
import { IConfirmModalInfo } from "../components/systemModal/ConfirmModal";
import { IAlertModalInfo } from "../components/systemModal/AlertModal";
import { IDetailModalInfo } from "../components/ItemDetailModal";
import { getFromUrl } from "../utils/getFromUrl";
import { LocalStorage } from "../utils/localstorage/StorageAddition";
import { CACHE, LangSet, getLangCode } from "../data/lang.static";
import { s8 } from "../utils/s4";

export type TUseAppContext = ReturnType<typeof useAppContextProvider>;

export const useAppContextProvider = ({ ...props }) => {
 const urlId = getFromUrl("id");
 const [updateKey, setUpdateKey] = useState(0);
 const mid = LocalStorage.getItem("mid");
 if (urlId) LocalStorage.setItem("id", urlId);
 if (mid) LocalStorage.setItem("mid", s8());
 const id = urlId || LocalStorage.getItem("id");

 const {
  loading: authLoading,
  data: userResult,
  refetch: refetchUser,
 } = useUserCreateOrFindQuery({
  onCompleted(data) {
   if (data.UserCreateOrFind?.error?.code === "2000") {
    alert("already registed.");
   }
  },
  variables: {
   input: {
    nfcId: id,
   },
  },
 });
 const user = userResult?.UserCreateOrFind?.data;
 const langCode =
  CACHE.langCode || user?.langeCode || getLangCode(navigator.language);

 console.log("!!!langCode", langCode);

 useEffect(() => {
  if (!user?.langeCode) return;
  if (user?.langeCode !== CACHE.langCode) {
   LocalStorage.setItem("lang", user.langeCode);
   CACHE.langCode = user.langeCode;
   LangSet(user.langeCode!);
   setUpdateKey(updateKey + 1);
  }
 }, [user?.langeCode]);

 const promptModalHook = useModal<IPromptModalInfo>();
 const confirmModalHook = useModal<IConfirmModalInfo>();
 const alertModalHook = useModal<IAlertModalInfo>();
 const itemDetailModalHook = useModal<IDetailModalInfo>();

 const redirectToAuth = !authLoading && user;

 const langSearch = <T>(Obj: T): T => {
  if (Array.isArray(Obj)) {
   return Obj.map((obj) => {
    return langSearch(obj);
   }) as any;
  }
  return Object.fromEntries(
   Object.entries(Obj as any).map(([key, value]) => {
    const newLangKey = key.replace("_ko__", `_${langCode}__`);
    return [newLangKey, value] as any;
   })
  ) as any;
 };

 const lang = (obj: any) => {
  if (!obj) return "";
  return obj[langCode];
 };

 return {
  nfcId: id,
  lang,
  langSearch,
  user,
  langCode,
  refetchUser,
  authLoading,
  redirectToAuth,
  promptModalHook,
  confirmModalHook,
  alertModalHook,
  itemDetailModalHook,
  setUpdateKey,
  updateKey,
 };
};

export const AppContext = React.createContext<TUseAppContext>({} as any);
export const useAppContext = () => {
 return useContext(AppContext);
};
