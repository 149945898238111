import { createBrowserRouter, Outlet } from "react-router-dom";
import { createRoutesFromElements, Route } from "react-router-dom";
import DashboardLayout from "./Layout";
import Tostify from "../components/Toastfy";
import Page404 from "./404";
import { ProtectedRoute } from "./components/ProtectedRouter";
import { Paths } from "../data/const";
import SignupPage from "./SignupPage";
import Albums from "./Albums";
import Map from "./Map";
import MyPage from "./MyPage";
import { PlaceInformationApiWrap } from "./PlaceInformation";
import { MainPage } from "./MainPage";
import SearchPage from "./SearchPage";
import { Visit } from "./Visit";

export const Router = createBrowserRouter(
 createRoutesFromElements(
  <Route
   path="/"
   element={
    <div id="App">
     <Tostify />
     <Outlet />
    </div>
   }
  >
   <Route path={Paths["/signup"]} element={<SignupPage />} />
   <Route
    element={
     <ProtectedRoute>
      <DashboardLayout />
     </ProtectedRoute>
    }
   >
    <Route path={Paths["/"]} element={<MainPage />} />
    <Route path={Paths["/visit"] + "/:id"} element={<Visit />} />
    <Route path={Paths["/search"]} element={<SearchPage />} />
    <Route path={Paths["/album"]} element={<Albums />} />
    <Route path={Paths["/map"]} element={<Map />} />
    <Route path={Paths["/mypage"]} element={<MyPage />} />
    <Route path={Paths["/place"]} element={<PlaceInformationApiWrap />} />
   </Route>
   <Route path="*" element={<Page404 />} />
  </Route>
 )
);
