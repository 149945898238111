import { LocalStorage } from "../utils/localstorage/StorageAddition";
const langData = {
 Setting: {
  ko: "설정",
  en: "Setting",
  ja: "設定",
  "zh-Hans": "设置",
  de: "Einstellung",
  hi: "सेटिंग",
  fr: "Paramètre",
 },
 Already_Registed: {
  ko: "이미 등록된 NFC 밴드 입니다.",
  en: "This band already registed please use first device.",
  ja: "既に登録されているNFCバンドです。",
  "zh-Hans": "这个乐队已经注册，请使用第一台设备。",
  de: "Dieses Band ist bereits registriert. Bitte verwenden Sie das erste Gerät.",
  hi: "यह बैंड पहले से ही पंजीकृत है, कृपया पहली डिवाइस का उपयोग करें।",
  fr: "Ce groupe est déjà enregistré, veuillez utiliser le premier appareil.",
 },
 maximumNickNameLengthIs: {
  ko: "닉네임 최대글자수는 24글자입니다.",
  en: "The maximum nickname length is 24 characters.",
  ja: "ニックネームの最大文字数は24文字です。",
  "zh-Hans": "昵称的最大字符数为24个字符。",
  de: "Die maximale Länge des Spitznamens beträgt 24 Zeichen.",
  hi: "उपनाम की अधिकतम लंबाई 24 अक्षर है।",
  fr: "La longueur maximale du pseudo est de 24 caractères.",
 },
 Edit_Profile: {
  ko: "프로필 수정",
  en: "Edit Profile",
  ja: "プロフィールを編集",
  "zh-Hans": "编辑个人资料",
  de: "Profil bearbeiten",
  hi: "प्रोफ़ाइल संपादित करें",
  fr: "Modifier le profil",
 },
 Open_GoogleMap: {
  ko: "구글 지도를 여시겠습니까?",
  en: `Will you open Google map?`,
  ja: "Googleマップを開きますか？",
  "zh-Hans": "您要打开谷歌地图吗？",
  de: "Möchten Sie Google Maps öffnen?",
  hi: "क्या आप गूगल मैप खोलेंगे?",
  fr: "Voulez-vous ouvrir Google Maps ?",
 },
 R_Coupon_Used: {
  ko: "쿠폰이 사용처리 되었습니다.",
  en: `Coupon successfully applied.`,
  ja: "クーポンが適用されました。",
  "zh-Hans": "优惠券已成功应用。",
  de: "Gutschein erfolgreich angewendet.",
  hi: "कूपन सफलतापूर्वक लागू किया गया।",
  fr: "Coupon appliqué avec succès.",
 },
 Q_Willing_Coupon: {
  ko: "정말로 쿠폰을 사용하시겠습니까?",
  en: `Are you sure you want to use this coupon?`,
  ja: "本当にこのクーポンを使用しますか？",
  "zh-Hans": "您确定要使用此优惠券吗？",
  de: "Möchten Sie diesen Gutschein wirklich verwenden?",
  hi: "क्या आप निश्चित रूप से इस कूपन का उपयोग करना चाहते हैं?",
  fr: "Êtes-vous sûr de vouloir utiliser ce coupon ?",
 },
 SignUpWellComeText: {
  ko: "회원가입을 환영합니다!",
  en: `Welcome to sign up!`,
  ja: "サインアップを歓迎します！",
  "zh-Hans": "欢迎注册！",
  de: "Willkommen bei der Anmeldung!",
  hi: "साइन अप करने का स्वागत है!",
  fr: "Bienvenue pour vous inscrire!",
 },
 SignUpWellComeSubText: {
  ko: "원활한 경험을 위해, 몇 가지 세부사항을 입력해 주세요.",
  en: `To ensure a smooth experience, please enter a few details.`,
  ja: "スムーズな体験を保証するために、いくつかの詳細を入力してください。",
  "zh-Hans": "为了确保流畅的体验，请输入一些详细信息。",
  de: "Um ein reibungsloses Erlebnis zu gewährleisten, geben Sie bitte einige Details ein.",
  hi: "एक सुगम अनुभव सुनिश्चित करने के लिए, कृपया कुछ विवरण दर्ज करें।",
  fr: "Pour garantir une expérience fluide, veuillez saisir quelques détails.",
 },
 ProfileUpdateText: {
  ko: "프로필 업데이트를 환영합니다!",
  en: `Welcome to update your profile!`,
  ja: "プロファイルの更新を歓迎します！",
  "zh-Hans": "欢迎更新您的个人资料！",
  de: "Willkommen beim Aktualisieren Ihres Profils!",
  hi: "अपना प्रोफ़ाइल अपडेट करने का स्वागत है!",
  fr: "Bienvenue pour mettre à jour votre profil!",
 },
 ProfileUpdateSubText: {
  ko: "최신 정보를 유지하기 위해, 세부사항을 업데이트해 주세요.",
  en: `To keep your information up-to-date, please update your details.`,
  ja: "最新の情報を保つために、詳細を更新してください。",
  "zh-Hans": "为了保持最新的信息，请更新您的详细资料。",
  de: "Um Ihre Informationen auf dem neuesten Stand zu halten, aktualisieren Sie bitte Ihre Angaben.",
  hi: "अपनी जानकारी को नवीन रखने के लिए, कृपया अपना विवरण अपडेट करें।",
  fr: "Pour maintenir vos informations à jour, veuillez mettre à jour vos détails.",
 },
 noResultFoundText: {
  ko: "검색결과가 없습니다",
  en: `No result found.`,
  ja: "結果が見つかりませんでした。",
  "zh-Hans": "未找到结果。",
  de: "Keine Ergebnisse gefunden.",
  hi: "कोई परिणाम नहीं मिला।",
  fr: "Aucun résultat trouvé.",
 },
 PlacesTitle: {
  ko: "유명 명소",
  en: `Popular Destinations`,
  ja: "人気の目的地",
  "zh-Hans": "热门目的地",
  de: "Beliebte Reiseziele",
  hi: "लोकप्रिय स्थल",
  fr: "Destinations populaires",
 },
 Attractions: {
  ko: "어트랙션",
  en: `Attractions`,
  ja: "アトラクション",
  "zh-Hans": "景点",
  de: "Attraktionen",
  hi: "आकर्षण",
  fr: "Attractions",
 },
 Cafe: {
  ko: "카페",
  en: `Cafe`,
  ja: "カフェ",
  "zh-Hans": "咖啡馆",
  de: "Café",
  hi: "कफी",
  fr: "Café",
 },
 Information: {
  ko: "투어 정보",
  en: `Information`,
  ja: "情報",
  "zh-Hans": "信息",
  de: "Information",
  hi: "जानकारी",
  fr: "Information",
 },
 Convention: {
  ko: "컨벤션",
  en: `Convention`,
  ja: "コンベンション",
  "zh-Hans": "会议",
  de: "Konvention",
  hi: "सम्मेलन",
  fr: "Convention",
 },
 MainPageTopText: {
  ko: "부산의 다양한 문화를 경험해보세요.",
  en: `Experience Busan's diverse culture`,
  ja: "釜山の多様な文化を体験してください",
  "zh-Hans": "体验釜山的多元文化",
  de: "Erleben Sie die vielfältige Kultur von Busan.",
  hi: "बुसान की विविध संस्कृति का अनुभव करें",
  fr: "Découvrez la diversité culturelle de Busan",
 },
 Uploading: {
  ko: "업로드중...",
  en: `Uploading...`,
  ja: "アップロード中...",
  "zh-Hans": "上传中...",
  de: "Hochladen...",
  hi: "अपलोड हो रहा है...",
  fr: "Téléchargement...",
 },
 Please_Upload: {
  ko: "사진을 업로드해주세요.",
  en: `Please upload your photo`,
  ja: "写真をアップロードしてください",
  "zh-Hans": "请上传您的照片",
  de: "Bitte laden Sie Ihr Foto hoch.",
  hi: "कृपया अपनी फोटो अपलोड करें",
  fr: "Veuillez télécharger votre photo",
 },
 SearchBarPlaceHolder: {
  ko: "당신의 여행 목적지는 어디인가요?",
  en: `Search your tourist destinations`,
  ja: "旅行先を検索",
  "zh-Hans": "搜索您的旅游目的地",
  de: "Suchen Sie Ihre Reiseziele",
  hi: "अपने पर्यटन स्थलों को खोजें",
  fr: "Recherchez vos destinations touristiques",
 },
 HOME: {
  ko: "홈",
  en: `HOME`,
  ja: "ホーム",
  "zh-Hans": "首页",
  de: "STARTSEITE",
  hi: "होम",
  fr: "ACCUEIL",
 },
 ALBUMS: {
  ko: "앨범",
  en: `ALBUMS`,
  ja: "アルバム",
  "zh-Hans": "相册",
  de: "ALBEN",
  hi: "एल्बम",
  fr: "ALBUMS",
 },
 MAP: {
  ko: "지도",
  en: `MAP`,
  ja: "マップ",
  "zh-Hans": "地图",
  de: "KARTE",
  hi: "नक्शा",
  fr: "CARTE",
 },
 MY: {
  ko: "내정보",
  en: `MY`,
  ja: "マイ",
  "zh-Hans": "我的信息",
  de: "MEIN",
  hi: "मेरा",
  fr: "MON",
 },
 album_subtitle: {
  ko: "부산의 다양한 문화를 경험해보세요.",
  en: `Experience Busan's diverse culture`,
  ja: "釜山の多様な文化を体験してください。",
  "zh-Hans": "体验釜山的多元文化。",
  de: "Erleben Sie die vielfältige Kultur von Busan.",
  hi: "बुसान की विविध संस्कृति का अनुभव करें",
  fr: "Découvrez la diversité culturelle de Busan",
 },
 Brochure: {
  ko: "바우처",
  en: `Brochure`,
  ja: "パンフレット",
  "zh-Hans": "手册",
  de: "Broschüre",
  hi: "पुस्तिका",
  fr: "Brochure",
 },
 My_HISTORY: {
  ko: "나의 여정",
  en: `My History`,
  ja: "私の歴史",
  "zh-Hans": "我的历史",
  de: "Meine Geschichte",
  hi: "मेरा इतिहास",
  fr: "Mon historique",
 },
 View: {
  ko: "보기",
  en: `View`,
  ja: "表示",
  "zh-Hans": "查看",
  de: "Ansehen",
  hi: "देखें",
  fr: "Voir",
 },
 Coupon_Used: {
  ko: "사용완료",
  en: `Used`,
  ja: "使用済み",
  "zh-Hans": "已使用",
  de: "Verwendet",
  hi: "उपयोग किया गया",
  fr: "Utilisé",
 },
 PHOTO_ALBUMS: {
  ko: "방문 앨범",
  en: `Visit Albums`,
  ja: "訪問アルバム",
  "zh-Hans": "访问相册",
  de: "Besuchs-Alben",
  hi: "यात्रा एल्बम",
  fr: "Albums de visite",
 },
 Shorts: {
  ko: "쇼츠",
  en: `Shorts`,
  ja: "ショーツ",
  "zh-Hans": "短片",
  de: "Shorts",
  hi: "छोटा",
  fr: "Shorts",
 },
 Tourist_Point_label: {
  ko: "투어점수",
  en: `Tourist Points`,
  ja: "観光ポイント",
  "zh-Hans": "旅游点数",
  de: "Touristenpunkte",
  hi: "पर्यटक अंक",
  fr: "Points touristiques",
 },
 a_Of_b: {
  ko: "중",
  en: `of`,
  ja: "の",
  "zh-Hans": "的",
  de: "von",
  hi: "का",
  fr: "de",
 },
 Location: {
  ko: "위치",
  en: `Location`,
  ja: "位置",
  "zh-Hans": "位置",
  de: "Ort",
  hi: "स्थान",
  fr: "Emplacement",
 },
 See_All: {
  ko: "더보기",
  en: `See All`,
  ja: "すべてを見る",
  "zh-Hans": "查看全部",
  de: "Alle anzeigen",
  hi: "सभी देखें",
  fr: "Voir tout",
 },
 Coupon: {
  ko: "쿠폰",
  en: `Coupon`,
  ja: "クーポン",
  "zh-Hans": "优惠券",
  de: "Gutschein",
  hi: "कूपन",
  fr: "Coupon",
 },
 Use: {
  ko: "사용",
  en: `Use`,
  ja: "使用する",
  "zh-Hans": "使用",
  de: "Verwenden",
  hi: "उपयोग करें",
  fr: "Utiliser",
 },
 country_label: {
  ko: "국가",
  en: `Country`,
  ja: "国",
  "zh-Hans": "国家",
  de: "Land",
  hi: "देश",
  fr: "Pays",
 },
 age_range_label: {
  ko: "연령",
  en: `Age range`,
  ja: "年齢範囲",
  "zh-Hans": "年龄范围",
  de: "Altersbereich",
  hi: "आयु सीमा",
  fr: "Tranche d'âge",
 },
 Nickname: {
  ko: "닉네임",
  en: "Nickname",
  ja: "ニックネーム",
  "zh-Hans": "昵称",
  de: "Spitzname",
  hi: "उपनाम",
  fr: "Pseudo",
 },
 Business_card: {
  ko: "명함",
  en: `Business card`,
  ja: "名刺",
  "zh-Hans": "名片",
  de: "Visitenkarte",
  hi: "व्यापारिक कार्ड",
  fr: "Carte de visite",
 },
 Notifications: {
  ko: "알림",
  en: `Notifications`,
  ja: "通知",
  "zh-Hans": "通知",
  de: "Benachrichtigungen",
  hi: "सूचनाएं",
  fr: "Notifications",
 },
 VisitMessage: {
  ko: "새로운 장소를 방문 하셨습니다.",
  en: `You have visited a new place`,
  ja: "新しい場所を訪れました",
  "zh-Hans": "您访问了一个新地方",
  de: "Sie haben einen neuen Ort besucht",
  hi: "आपने एक नए स्थान का दौरा किया है",
  fr: "Vous avez visité un nouvel endroit",
 },
 already_set_visit: {
  ko: "이미 방문한 장소입니다.",
  en: `Already registered place`,
  ja: "すでに登録された場所",
  "zh-Hans": "已注册的地点",
  de: "Bereits registrierter Ort",
  hi: "पहले से ही रजिस्टर किया गया स्थान",
  fr: "Lieu déjà enregistré",
 },
 Language: {
  ko: "언어",
  en: "Language",
  ja: "言語",
  "zh-Hans": "语言",
  de: "Sprache",
  hi: "भाषा",
  fr: "Langue",
 },
 Language_Desc: {
  ko: "표기 언어를 변경합니다.",
  en: "Change the language displayed",
  ja: "表示言語を変更します。",
  "zh-Hans": "更改显示语言",
  de: "Ändere die angezeigte Sprache",
  hi: "दिखाई जाने वाली भाषा बदलें",
  fr: "Modifier la langue affichée",
 },
};

export const L: Record<keyof typeof langData, string> = {} as any;

export const LagnList = ["ko", "en", "zh-Hans", "de", "ja", "hi", "fr"];

export const LangPair = {
 ko: "한국어",
 en: "English",
 "zh-Hans": "简体中文",
 de: "Deutsch",
 ja: "日本語",
 hi: "हिन्दी",
 fr: "Français",
};
export const awsLangCodeMap: Map<string, string> = new Map([
 ["ko", "ko-KR"],
 ["en", "en-US"], // English (United States)
 ["zh-Hans", "cmn-CN"], // Mandarin "zh-Hans"nese (Simplified, "zh-Hans"na)
 ["zh-Hans", "cmn-CN"],
 ["de", "de-DE"], // German (Germany)
 ["ja", "ja-JP"], // Japanese (Japan)
 ["hi", "hi-IN"], // Hindi (India)
 ["fr", "fr-FR"], // French (France)
 ["it", "it-IT"], // Italian (Italy)
 ["pt", "pt-BR"], // Portuguese (Brazil), assuming Brazilian Portuguese is more widely used in AWS contexts
 ["ru", "ru-RU"], // Russian (Russia)
 ["es", "es-ES"], // Spanish (Spain), could also use "es-US" for US Spanish
 ["id", "id-ID"], // Indonesian (Indonesia)
 ["tr", "tr-TR"], // Turkish (Turkey)
 ["nl", "nl-NL"], // Dutch (Netherlands)
 ["ar", "arb"], // Arabic (Standard)
 ["rm", "ro-RO"], // Assuming "rm" is Romanian (Romania), as "rm" typically stands for Romansh, which is less likely
]);

const reversedMap = new Map<string, string>(
 Array.from(awsLangCodeMap, (a) => a.reverse() as any)
);

export const CACHE = {
 langCode: LocalStorage.getItem("lang") || "",
};
export const getLangCode = (_lang: string) => {
 if (CACHE.langCode) return CACHE.langCode;
 const lang = _lang;
 let langCode = "en";
 if (LagnList.includes(lang)) {
  langCode = lang;
 } else if (reversedMap.get(lang)) {
  langCode = reversedMap.get(lang) as any;
 }
 return langCode;
};

export const LangSet = (lang: string) => {
 const langCode = getLangCode(lang);

 const keys = Object.keys(langData);
 keys.forEach((key) => {
  //@ts-ignore
  L[key] = langData[key][langCode] || langData[key]["en"];
 });
 console.log("@@@L", { L, langCode });
};
export default langData;
